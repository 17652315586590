<template>
  <div class="card" style="margin: auto;">
    <h1 style="margin-top: 50px">Register</h1>
    <h6 style="text-align: center;">The registration process requires a Project Para team member to review and approve your account request.
      You can expect your request will be reviewed within 1-2 business days. Once authorized, you will receive another
      email with further instructions on logging into Canvas.</h6>
    <div class="form-control">
      <form @submit.prevent="registerUser">
        <RegistrationFormComposition :submitText="'Register'" :superUserRegistering="false" :selfRegistering="true"
                                     :default-country="'US'"
                                     :default-state="'NE'"
                                     :editing-existing="false"/>
      </form>
    </div>
  </div>
  <div>
    <router-link :to="{ name: 'Login' }"
    >Already have an account? Sign in here.
    </router-link
    >
  </div>
</template>

<script>
import RegistrationFormComposition from "@/views/Registration/RegistrationFormComposition";

export default {
  name: "SelfRegisterForm",
  components: {RegistrationFormComposition},

}
</script>

<style scoped>
.card {
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  align-content: center;
  border-radius: 0;
  padding: 1%;
}


</style>